import { KeysMatching } from "../util/types/keysMatching";
import { Dictionary } from 'lodash';
import { Profile, ProfileInterface } from "./profile.type";
import { PropMask } from "./propMask.type";
import { ROLES } from "./roles.type";
import { StringValues, Value, Values } from "./value.type";

export interface RealmInterface extends ProfileInterface<Realm, RealmInterface> {
	tagline?: string;
	relic: Value;
	nature: {
		geography: StringValues;
		flora: Values;
		fauna: Values;
		magical?: Values;
	}
	npcs: Values;
	architecture: StringValues;
	society: {
		character: Values;
		motivations: Values;
		groups: Values;
		socialIssues: Values;
	}
	prominentLocations: Values;
	prominentResidents: Values;
	history: Values;
}

// Merge constructor: https://stackoverflow.com/questions/53128744/typescript-automatically-get-interface-properties-in-a-class/60047014#60047014
export interface Realm extends RealmInterface {};  
export class Realm extends Profile<Realm, RealmInterface> {

	restrictions: PropMask<Realm>[] = [
		new PropMask("meta", [ROLES.ARTIST, ROLES.ALPHA_READER]),
	];

	public propsToRender: (KeysMatching<RealmInterface, Dictionary<Values>> | { label: string; key: KeysMatching<RealmInterface, Values>; })[] = [
		{
			label: 'Relic',
			key: 'relic',
		},
		'nature',
	];

}
