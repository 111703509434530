import { CHARACTER_IDS } from "../.compiledContent/characters/CHARACTER_IDS";
import { REALM_IDS } from "../.compiledContent/realms/REALM_IDS";
import { CONTRIBUTOR_IDS } from "../.compiledContent/contributors/CONTRIBUTOR_IDS";
import { getContributor, getFullArtworkFilename } from "../services/contributors/contributors";

export interface ArtworkInterface {
    id: string;
    filename: string;
    character?: CHARACTER_IDS;
    realm?: REALM_IDS;

    /**
    * @format date
    */
    date: Date;
    
    title: string;
    artistId: CONTRIBUTOR_IDS;
    notes?: string;
}

export interface Artwork extends ArtworkInterface{}
export class Artwork {

    constructor(init: Artwork, id: string) {
		Object.assign(this, init, {id});
        this.filename = getFullArtworkFilename(init.filename);
	}

    get artist() {
        return getContributor(this.artistId);
    }
}
