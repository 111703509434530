export enum ARTWORK_IDS {
	"Gauss-portrait.png" = "Gauss-portrait.png",
	"Xelen_Portrait_50.png" = "Xelen_Portrait_50.png",
	"Cylynn-transparent-bg.png" = "Cylynn-transparent-bg.png",
	"bg-library.png" = "bg-library.png",
	"fidei-revelation-cover.png" = "fidei-revelation-cover.png",
	"fidei-realm-tree-stained-glass.png" = "fidei-realm-tree-stained-glass.png",
	"game-over-anansi.png" = "game-over-anansi.png",
	"war-of-haven.png" = "war-of-haven.png",
	"bg.png" = "bg.png",
	"bg-tower.png" = "bg-tower.png"
}