import NiceModal, { useModal } from "@ebay/nice-modal-react";
import classNames from "classnames/bind";
import { capitalize, map } from "lodash";
import React, { useEffect, useState } from "react";
import { BsBook, BsDownload, BsFileText, BsMoon, BsPersonCircle, BsSun } from "react-icons/bs";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { darkModeSlice } from "../../redux/darkModeSlice";
import { readerModePreferenceSlice } from "../../redux/readerModeSlice";
import { useAppDispatch, useAppSelector } from "../../redux/reduxHooks";
import FootnoteModal from "../FootnoteContentComponent/FootnoteModal";
import LoginComponent from "../LoginComponent/LoginComponent";
import s from "./BannerComponent.module.css";
import { locationIsStory } from "../../AppRoutes";
const cx = classNames.bind(s);

const BannerComponent: React.FC<{}> = () => {
	const titleOptions = useAppSelector((sel) => sel.banner).titles;
	const { value: readerMode, enabled: readerModeEnabled } = useAppSelector(
		(sel) => sel.readerModePreference
	);
	const [activeTitleIndex, setActiveTitleIndex] = useState(0);
	useEffect(() => {
		const interval = setInterval(() => {
			setActiveTitleIndex(
				activeTitleIndex === titleOptions.length - 1
					? 0
					: activeTitleIndex + 1
			);
		}, 10000);
		return () => {
			clearInterval(interval);
		};
	}, [titleOptions, activeTitleIndex]);

	const darkMode = useAppSelector((sel) => sel.darkMode).darkMode;
	const dispatch = useAppDispatch();
	function toggleDarkMode() {
		dispatch(darkModeSlice.actions.toggleMode());
	}
	const location = useLocation();
	const storyId = locationIsStory(location);
	
	function toggleReaderMode() {
		dispatch(readerModePreferenceSlice.actions.toggle());
	}

	function downloadStory() {
		window.open(`#/download/${storyId}?print=true`, '_blank')?.focus()
	}

	const loginModal = useModal(FootnoteModal);

	function showLoginModal() {
		NiceModal.show(FootnoteModal, {
			children: <LoginComponent/>,
		});
	}

	return (
		<>
			<header className={`${s.banner} noPrint`}>
				<div className={s.left}></div>
				<div className={s.titleContainer}>
					{map(titleOptions, (cOpt, idx) => (
						<span
							key={idx}
							className={`${s.titleTransition} ${
								idx === activeTitleIndex
									? s.titleTransitionOn
									: s.titleTransitionOff
							}`}
						>
							{cOpt}
						</span>
					))}
					<span className={s.heightFix}>-</span>
				</div>
				<div className={s.right}>
					{storyId && <> 
						{ readerModeEnabled && (
							<span
								className={cx(s.navButton, s.readerModeToggle)}
								onClick={toggleReaderMode}
								data-tooltip-id="tooltipId"
								data-tooltip-content={`Read in ${capitalize(
									readerMode === "page" ? "scroll" : "page"
								)} mode`}
							>
								{readerMode === "scroll" && <BsBook />}
								{readerMode === "page" && <BsFileText />}
							</span>
						)}
						<span
							className={s.navButton}
							onClick={downloadStory}
							data-tooltip-id="tooltipId"
							data-tooltip-content={'Download Story'}
						>
							{<BsDownload/>}
						</span>
					</>}
					<span
						className={s.navButton}
						onClick={toggleDarkMode}
						data-tooltip-id="tooltipId"
						data-tooltip-content={`Switch to ${
							darkMode ? "Light" : "Dark"
						} mode`}
					>
						{!darkMode && <BsMoon />}
						{darkMode && <BsSun />}
					</span>
					<span
						className={s.navButton}
						onClick={showLoginModal}
						data-tooltip-id="tooltipId"
						data-tooltip-content="Login"
					>
						<BsPersonCircle />
					</span>
				</div>
				<div className={s.bg}></div>
			</header>
			
		</>
	);
};

export default BannerComponent;
