import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { updatePageTitle } from '../util/updatePageTitle';

interface BannerInterface {
    titles: string[],
}

const initialState: BannerInterface = {
    titles: ['Welcome to Haven!', "Tales from Haven Online"],
}

export const bannerSlice = createSlice({
    name: 'banner',
    initialState,
    reducers: {
        set: (state, action: PayloadAction<BannerInterface>) => {
            Object.assign(state, action.payload);
            updatePageTitle([state.titles[0]]);
        },
    }
})
