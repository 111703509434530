import { Artwork } from "../types/artwork.type";
import { ContentServiceClass } from "./contentService.class";
import { ARTWORK_IDS } from '../.compiledContent/artworks/ARTWORK_IDS';
import { EnumType } from "typescript";
import { get, map } from "lodash";

class ArtworkContentService extends ContentServiceClass<Artwork, Artwork, ARTWORK_IDS> {
    constructor() {
        super('artwork', Artwork, ARTWORK_IDS);
    }

    /**
     * Reverse looks-up the artwork for a given profile (e.g. a character or realm).
     * @param profileId A profile you want the artwork for.
     * @param profileType The type of profile it is.
     * @returns 
     */
    getForProfile(profileId: EnumType, profileType: 'character' | 'realm') {
        return map(this.entries).filter(a => get(a, profileType) === profileId as any);
    }
}

export const artworkService = new ArtworkContentService()