import { configureStore, Slice } from "@reduxjs/toolkit";
import { Reducer } from "react";
import { rememberEnhancer, rememberReducer } from "redux-remember";
import { authSlice } from "./authSlice";
import { backgroundSlice } from "./backgroundSlice";
import { curDateSlice } from "./curDateSlice";
import { rolesSlice } from "./rolesSlice";
import { storiesReadSlice } from "./storiesReadSlice";
import { darkModeSlice } from "./darkModeSlice";
import { bannerSlice } from "./bannerSlice";
import { readerModePreferenceSlice } from "./readerModeSlice";

const rememberedReducers = {
	curDate: curDateSlice.reducer,
	storiesRead: storiesReadSlice.reducer,
	auth: authSlice.reducer,
	roles: rolesSlice.reducer,
	darkMode: darkModeSlice.reducer,
	readerModePreference: readerModePreferenceSlice.reducer,
};
rememberedReducers as ReducerMap;

const unrememberedReducers = {
	background: backgroundSlice.reducer,
	banner: bannerSlice.reducer,
};
unrememberedReducers as ReducerMap;


const rememberedKeys = Object.keys(rememberedReducers);
const allReducers = { ...rememberedReducers, ...unrememberedReducers };

export const store = configureStore({
	// Setting `middleware` alongside `rememberReducer()` seems to break all typechecking. Casting to default type fixes this.
	reducer: rememberReducer(allReducers) as unknown as typeof allReducers,

	// Middleware must be before Enhancers. See: https://redux-toolkit.js.org/usage/migrating-rtk-2#configurestore-field-order-for-middleware-matters
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: {
			ignoredPaths: ['login.client'],
		},
	}),

	enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(
		rememberEnhancer(window.localStorage, rememberedKeys, {
			persistWholeStore: true,
		})
	),
});

// Infer types.
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

interface ReducerMap {
	[key: string]: Reducer<any, any>;
}

