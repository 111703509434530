import { Artwork } from "./artwork.type";

export class Contributor {
    id: string;
    name: string;
    href?: string;
    works?: Artwork[];
    email?: string;
    desc: string;

    constructor(init: Contributor, id: string) {
		Object.assign(this, init, {id});
	}
}

export type ContributorList = Contributor[];