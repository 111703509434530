import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DarkModeInterface {
    darkMode: boolean,
}

const initialState: DarkModeInterface = {
    darkMode: false,
}

export const darkModeSlice = createSlice({
    name: 'darkMode',
    initialState,
    reducers: {
        set: (state, action: PayloadAction<DarkModeInterface>) => {
            Object.assign(state, action.payload)
        },
        toggleMode: (state) => {
            state.darkMode = !state.darkMode;
        }
    }
})
