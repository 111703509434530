import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReaderMode } from '../types/readerMode.type';

interface ReaderModeInterface {
    value: ReaderMode,
    enabled: boolean;
}

const initialState: ReaderModeInterface = {
    value: 'page',
    enabled: true,
}

export const readerModePreferenceSlice = createSlice({
    name: 'readerMode',
    initialState,
    reducers: {
        set: (state, action: PayloadAction<Partial<ReaderModeInterface>>) => {
            Object.assign(state, action.payload)
        },
        toggle: (state) => {
            state.value = (state.value === 'page') ? 'scroll' : 'page';
        }

    }
})
