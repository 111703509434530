import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ARTWORK_IDS } from '../.compiledContent/artworks/ARTWORK_IDS';


interface BackgroundStateInterface {
    /** A filename of an image in the `/art` dir. */
    image: ARTWORK_IDS;

    /** Between 0-1. */
    opacity?: number;

    /** The color behind the image. */
    color?: string;
}

const initialState: BackgroundStateInterface = {
    image: ARTWORK_IDS['bg.png'],
    opacity: 1,
    color: '#FFF',
}

export const backgroundSlice = createSlice({
    name: 'background',
    initialState,
    reducers: {
        set: (state, action: PayloadAction<BackgroundStateInterface>) => {
            Object.assign(state, initialState, action.payload)
        },
    }
})
