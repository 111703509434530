import s from "./FootnoteModal.module.css";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { MdOutlineClose } from "react-icons/md";

export default NiceModal.create<{
	backgroundColor?: string;
	children: React.ReactNode;
}>((p) => {
	const modal = useModal();

	function hide() {
		modal.remove();
	}

	return (
		<div className={s.wrapper} onClick={hide} title="Close">
			<div
				className={s.modal}
				onClick={(e) => e.stopPropagation()}
				style={{ backgroundColor: p.backgroundColor }}
				title=""
			>
				<span className={s.closeButton} onClick={hide} title="Close">
					<MdOutlineClose />
				</span>
				<div>{p.children}</div>
			</div>
		</div>
	);
});
