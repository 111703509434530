import Matter from 'gray-matter';
import { Dictionary, get, mapValues } from "lodash";
import {
	createStoryOrCollection,
	Story,
	StoryCollection,
	StoryCollectionDef,
	StoryDef,
} from "../types/story.type";
import { STORY_IDS } from "../.compiledContent/stories/STORY_IDS";
import { mdService } from './md.service';

const PATH_SEPARATOR = '_';

export const STORIES: Dictionary<Story | StoryCollection> = mapValues(
	require('../.compiledContent/stories/stories.json') as Dictionary<StoryDef | StoryCollectionDef>, // TODO: See if we can replace cast with better validation, such as JSON Schema.
	(se, key) => createStoryOrCollection(se, key as STORY_IDS)
);

export function getStoryById(id?: string) {
	if (!id) return null;
	const path = id.replaceAll(PATH_SEPARATOR, ".children.");
	const res = get(STORIES, path, null);
	return res;
}

export function prettyPrintStoryId(id: STORY_IDS) {
	if (!id) return;
	const supertitle = getStoryById(id)?.getSupertitle();
	return supertitle ? `"${supertitle}"` : 'an unreleased story';
}

/**
 * Returns markdown content for the story.
 */
export async function getStoryContent(story: Story) {
	const storyText = await mdService.get(story.filePath).then(txt => {
		// Remove copyright line, if any, preceding metadata.
		const yamlIndex = txt.indexOf("---");
		return (yamlIndex !== 0) ? txt.substring(yamlIndex) : txt
	});
	return Matter(storyText);
}