import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ROLES } from '../types/roles.type';
import { difference, union } from 'lodash';

const initialState: {
    value: ROLES[],
} = {
    value: [ROLES.READER],
}

export const rolesSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
        add: (state, action: PayloadAction<ROLES>) => {
            state.value = union(state.value, [action.payload]);
        },
        delete: (state, action: PayloadAction<ROLES>) => {
            state.value = difference(state.value, [action.payload]);
        },
        update: (state, action: PayloadAction<ROLES[]>) => {
            state.value = action.payload;
        },
    }
})
