import { Suspense, useEffect } from "react";
import { HashRouter } from "react-router-dom";
import AppRoutes from "../../AppRoutes";
import { useAppSelector } from "../../redux/reduxHooks";
import s from "./AppBaseComponent.module.css";
import React from "react";
import Loading from "../LoadingComponent/LoadingComponent";
import { colord } from 'colord';
import BannerComponent from "../BannerComponent/BannerComponent";
import {Tooltip} from "react-tooltip";
import ArtAttributionComponent from "../ArtAttributionComponent/ArtAttributionComponent";
import { artworkService } from "../../services/artwork.service";
import classNames from "classnames";
const cx = classNames.bind(s);

const copyrightYear = new Date().getFullYear();

const AppBaseComponent: React.FC<{}> = (p) => {
	const bg = useAppSelector((sel) => sel.background);
	const darkMode = useAppSelector(sel => sel.darkMode).darkMode;
	const backgroundColor = (darkMode && bg.color) ? colord(bg.color).invert().toHex() : bg.color;
	const bgArtwork = artworkService.get(bg.image)!;

	const backgroundImageStyle = {
		backgroundImage: `url(${bgArtwork.filename})`,
		backgroundColor,
	};
	const backgroundOverlayStyle = {
		backgroundColor,
		opacity: 1-(bg.opacity ?? 1),
	};

	// DarkMode
	useEffect(() => {
		if (darkMode) document.body.classList.add('darkMode');
		else document.body.classList.remove('darkMode');
	}, [darkMode]);


	return (
		<>
			<div className={`${s.container} fadeIn`}>
				<div className={s.routerContainer}>
					<HashRouter>
						<BannerComponent/>
						<Suspense fallback={<Loading />}>
							<AppRoutes></AppRoutes>
						</Suspense>
					</HashRouter>
				</div>
				<div className={s.footer}>
					<a className={s.copyright} href="https://chris.backofen.us">
						&#169; {copyrightYear} Chris Backofen
					</a>
					<span className={cx(s.copyright, 'noPrint')}><ArtAttributionComponent label="Background Artist" artwork={bgArtwork}/></span>
				</div>
			</div>
			<div
				className={cx(s.bgContainer, s.color, 'noPrint')}
				style={backgroundOverlayStyle}
			/>
			{/* TODO: Add attribution for background image */}
			<div
				className={cx(s.bgContainer, s.image, 'fadeIn', 'noPrint')}
				style={backgroundImageStyle}
			></div>
			<Tooltip id="tooltipId" className={s.tooltip}/>
		</>
	);
};

export default AppBaseComponent;
