import { store } from './redux/store';
import { Provider } from 'react-redux';
import AppBaseComponent from "./components/AppBaseComponent/AppBaseComponent";
import NiceModal from '@ebay/nice-modal-react';

// Add dependency required for gray-matter. This is a trash way to do this, but not seeing any better options.
window.Buffer = window.Buffer || require("buffer").Buffer;

function App() {
	return (
		<Provider store={store}>
			<NiceModal.Provider>
				<AppBaseComponent />
			</NiceModal.Provider>
		</Provider>
	);
}

export default App;
