import { Dictionary, mapValues } from "lodash";
import pluralize, { plural } from "pluralize";

// TODO: Allow hydrating objects. (Probably need a map function, to customize constructor inputs.)

interface ID {
    id: string;
}

/**
 * A singleton service to load indexed content, like Characters and Realms. (May work on Stories, too.) 
 * @typeParam ClassType The instantiable class, e.g. Realm. 
 * @typeParam InterfaceType The interface, e.g. RealmInterface. (Can be a class.)
 * @typeParam EnumType The enum representing the data's IDs, e.g. REALM_IDS.
 * @param typeName The singular name of the type of content, e.g. realms. Used to fetch content data via `require()`.
 * @param classType An object reference to the instantiable Class specified in ClassType, e.g. Realm.
 * @param realmIds An object reference to the IDs enum specified in EnumType, e.g. REALM_IDS.
 */
export class ContentServiceClass<
    ClassType extends ID, 
    InterfaceType, // TODO: Extend ID. (Requires redoing metadata compiler type checking interface conventions, so they can include the ID.)
    EnumType extends string
> {
    protected entries: { [key in EnumType]: ClassType };
    public ids: string[];

    constructor(
        public typeName: string,
        private classType: { new(t: InterfaceType, id: string): ClassType },
        ids: Dictionary<string>,
    ) {
        const pluralTypeName = pluralize(this.typeName);
        const file = require(`../.compiledContent/${pluralTypeName}/${pluralTypeName}.json`);
        this.entries = mapValues(file as { [key in EnumType]: InterfaceType }, (e, k) => new this.classType(e,k));
        this.ids = Object.values(ids);
    }

    /**
     * Returns all entries.
     */
    getAll() {
        return this.entries;
    }

    /**
     * Returns a single entry.
     */
    get(id: EnumType) {
        return this.entries[id];
    }

    /**
     * Returns a React context for this type of content. 
     */
    // getContext(initialValue: Partial<InterfaceType>) {
    //     if (!this.context) this.context = React.createContext();
    //     return this.context;
    // }

    /**
     * Indicates whether a given string is a valid ID for this content type.
     * @param id The string to check.
     * @returns The string typed, if it's valid. Else null.
     */
    validateId(id?: string) {
        if (id && this.ids.includes(id)) return id as EnumType;
        return null;
    }

}