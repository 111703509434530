import { Range } from "../util/types/range.type";

// Support CommonJS for use with tools/compile-metadata:
import pkg from 'lodash';
const { assign, isNil } = pkg;

/**
 * TODO: Figure out how to make this reading-restricted, if necessary.
 */
export class HavenEvent implements Range<number> {
	title?: string;
	desc?: string;
	begin: number;
	end: number;

	constructor(
		begin: HavenEvent["begin"],
		end?: HavenEvent["end"],
		partial?: Partial<HavenEvent>
	) {
		assign(this, { begin }, { end }, partial);
		if (isNil(this.end)) this.end = this.begin;
	}

	get isPoint(): boolean {
		return this.begin === this.end;
	}
	get isSpan(): boolean {
		return !this.isPoint;
	}
	
	public toString(): string {
		return havenEventToString(this);
	}
}

export function havenEventToString(event: Partial<HavenEvent>) {
	const {begin, end} = event;
	if (!begin && !end) return 'Unknown';
	if (begin === end) return havenDateToString(begin);
	else return `${begin}–${havenDateToString(end)}`
}

export function havenDateToString(date?: number): string {
	if (!date) return 'Unknown';
	return `${date} HE`;
}