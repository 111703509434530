export enum CHARACTER_IDS {
	"ANNIE" = "ANNIE",
	"ARRUAHH" = "ARRUAHH",
	"BOPH" = "BOPH",
	"BREWBEARD" = "BREWBEARD",
	"CYLYNN_STEELHARDT" = "CYLYNN_STEELHARDT",
	"GAUSS" = "GAUSS",
	"ICHOROS" = "ICHOROS",
	"JUDY" = "JUDY",
	"LUCIFER" = "LUCIFER",
	"THE_FEARSOME_SPIRIT" = "THE_FEARSOME_SPIRIT",
	"XELEN" = "XELEN"
}