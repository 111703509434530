export enum ROLES {
	ADMIN = "ADMIN",
	READER = "READER",
	ARTIST = "ARTIST",
	ALPHA_READER = "ALPHA_READER",
	BETA_READER = "BETA_READER",
}

interface Role {}

export const roles: { [t in ROLES]: Role } = {
	ADMIN: {},
	ALPHA_READER: {},
	BETA_READER: {},
	ARTIST: {},
	READER: {},
};
