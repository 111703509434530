import { RecursiveKeyOf } from "../util/types/recursiveKeys.type";
import { ROLES } from "./roles.type";

/**
 * Defines ability to access roles.
 */
export class PropMask<T extends object> {
	constructor(
		public prop: RecursiveKeyOf<T>,

		/**
		 * The roles allowed to access the content. (Admin is always allowed.)
		 */
		public roles: ROLES[]
	) {
		roles.push(ROLES.ADMIN);
	}
}
