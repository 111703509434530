import { StringValues, Value, Values } from "./value.type";
import { Restrictable } from "./restrictable.type";

export interface ProfileInterface<ClassType extends object, InterfaceType extends object> {
    name: string;
	published: boolean;
    aliases: Values;
    meta: {
		concept: Value;
		inspirations: Values;
		themes: string[];
		other: StringValues;
	}

}

// Merge constructor: https://stackoverflow.com/questions/53128744/typescript-automatically-get-interface-properties-in-a-class/60047014#60047014

export interface Profile<ClassType extends object, InterfaceType extends object> extends ProfileInterface<ClassType, InterfaceType> {};  
export abstract class Profile<ClassType extends object, InterfaceType extends object> extends Restrictable<ClassType> {
	id: string;
	constructor(init: InterfaceType, id: string) {
		super();
		Object.assign(this, init, {id});
	}
}
