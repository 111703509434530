import s from "./LoadingComponent.module.css";
import React, { useState } from "react";
import { GridLoader } from "react-spinners";
import { random } from "colord";
import classNames from "classnames/bind";
const cx = classNames.bind(s);

const Loading: React.FC<{
	loading?: boolean;
	children?: React.ReactNode;
}> = (p) => {

	const [color, setColor] = useState<string>();
  const loading = p.loading === undefined ? true : p.loading;

  if (!color) {
    const c = random();
    const bDiff = c.brightness() - .33;
    setColor(c.darken(bDiff).toHex());
  }
  
	return (
		<div className={s.wrapper}>
			<GridLoader
				color={color}
				size={30}
				aria-label="Loading Spinner"
        loading={loading}
        className={`${s.spinner} fadeIn ${cx({
          hide: !loading,
        })}`}
        speedMultiplier={.5}
			/>
			<div
        className={`${s.childContainer} fadeIn ${cx({
          hide: loading,
        })}`}
			>
				{p.children}
			</div>
		</div>
	);
};

export default Loading;
