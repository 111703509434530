import { Contributor } from '../../types/contributor.type';
import { map } from 'lodash';
import { ContentServiceClass } from '../contentService.class';
import { CONTRIBUTOR_IDS } from '../../.compiledContent/contributors/CONTRIBUTOR_IDS';


// TODO: Would be nice to use exclusively this, and erase all the other code here.
const contributorsService = new ContentServiceClass<Contributor, Contributor, CONTRIBUTOR_IDS>(
    'contributor',
    Contributor,
    CONTRIBUTOR_IDS,
)


const contributors: Contributor[] = map(contributorsService.getAll(), contributor => ({
    ...contributor, 
    works: contributor.works?.map(work => ({
        ...work, 
        artist: contributor,
        filename: getFullArtworkFilename(work.filename),
    })),
}));

export function getContributors() {
    return contributors;
}

export function getContributor(
    id: CONTRIBUTOR_IDS,
) {
    return contributorsService.get(id);
}

export function getFullArtworkFilename(filename: string) {
    return `${process.env.PUBLIC_URL}/content/art/${filename}`;
}