import { KeysMatching } from "../util/types/keysMatching";
import { HavenEvent } from "./havenEvent.type";
import { Profile, ProfileInterface } from "./profile.type";
import { PropMask } from "./propMask.type";
import { ROLES } from "./roles.type";
import { CharacterValues, RealmValue, StringValue, StringValues, Value, Values } from "./value.type";
import { Dictionary } from "lodash";

export interface CharacterInterface extends ProfileInterface<Character, CharacterInterface> {
	nicknames: Values;
	systemRoles: Values;
	
	identity: {
		occupations: Values;
		gender: string;
		sexuality: StringValue;
		demeanor: StringValues;
		speechStyle: StringValues;
		catchphrases: StringValues;
		mannerisms: string[];
		appreciates: Values;
		dislikes: Values;
		hobbies: string[];
		musicalPreferences: string[] | string;
		phobiasAndTraumas: Values;
		beliefs: Values;
		priorities: Values;
		personalityTraits?: Values;
		influences?: Values;
		progression: string[]; 
		strengths: Values;
		weaknesses: Values;
	}
	historicalInfo: {
		lifespan: Partial<HavenEvent>;
		origin: RealmValue | string;
		home: RealmValue;
		history: Values;
	}
	appearance: {
		ethnicity: StringValue;
		species: Value;
		height: string;
		weight: string;
		figureAndBuild: string;
		hair: StringValue;
		eyes: string;
		skin: StringValue;
		scars: Values;
		tattoos: Values;
		piercings: Values;
		accessories: Values;
		preferredClothing: Values;
		other: Values;
	}
	itemsUsuallyCarried: Values;
	skillsAndAbilities: Values;
	relations: {
		parents: CharacterValues;
		romanticPartners: CharacterValues;
		siblings: CharacterValues;
		friends: CharacterValues;
		associates: CharacterValues;
		progeny: CharacterValues;
		adoptiveChildren: CharacterValues;
		mentors: CharacterValues;
		rivals?: CharacterValues;
	}
}

// Merge constructor: https://stackoverflow.com/questions/53128744/typescript-automatically-get-interface-properties-in-a-class/60047014#60047014
export interface Character extends CharacterInterface {};  
export class Character extends Profile<Character, CharacterInterface> {

	restrictions: PropMask<Character>[] = [
		new PropMask("meta", [ROLES.ARTIST, ROLES.ALPHA_READER]),
		new PropMask("identity.catchphrases", [
			ROLES.ARTIST,
			ROLES.ALPHA_READER,
		]),
		new PropMask("appearance", [ROLES.ARTIST]),
		new PropMask("identity.demeanor", [
			ROLES.ARTIST,
			ROLES.ALPHA_READER,
		]),
		new PropMask("identity.progression", [ROLES.ARTIST]),
	];

	public propsToRender: (KeysMatching<CharacterInterface, Dictionary<Values>> | { label: string; key: KeysMatching<CharacterInterface, Values>; })[] = [
		'appearance',
		'historicalInfo',
		'identity',
		{
			label: 'Items Usually Carried',
			key: 'itemsUsuallyCarried',
		},
		{
			label: 'Skills and Abilities',
			key: 'skillsAndAbilities',
		},
		'relations',
	];
}
