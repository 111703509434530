export enum REALM_IDS {
	"Acadia" = "Acadia",
	"Aequa" = "Aequa",
	"Cor" = "Cor",
	"Crest" = "Crest",
	"Eden" = "Eden",
	"elemancia" = "elemancia",
	"Fidei" = "Fidei",
	"greyton" = "greyton",
	"Hell" = "Hell",
	"Hygge" = "Hygge",
	"Hyperion" = "Hyperion",
	"Icendar" = "Icendar",
	"Pyr" = "Pyr",
	"Ruran" = "Ruran",
	"Sohm" = "Sohm",
	"Sylva" = "Sylva",
	"Symulacra" = "Symulacra",
	"The Library of Alexandria" = "The Library of Alexandria",
	"Titan" = "Titan",
	"Vore" = "Vore"
}