import s from "./LoginComponent.module.css";
import React from "react";
import { createClient } from "@supabase/supabase-js";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { useAppSelector } from "../../redux/reduxHooks";

const supabase = createClient(
	"https://vnynsvpkvztufwgnvhpn.supabase.co",
	"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZueW5zdnBrdnp0dWZ3Z252aHBuIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDQxNTM4MzAsImV4cCI6MjAxOTcyOTgzMH0.Aod87fLutIUchFy3DcoZczn7K-Jl8eE491TYut4WiJw"
);

const LoginComponent: React.FC<{}> = (p) => {
	const darkMode = useAppSelector((sel) => sel.darkMode).darkMode;
	const theme = darkMode ? "dark" : "default";
	return (
		<div className={s.wrapper}>
			<Auth
				supabaseClient={supabase}
				appearance={{ theme: ThemeSupa }}
				theme={theme}
				providers={['google']}
			/>
		</div>
	);
};

export default LoginComponent;
