
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const INITIAL_DATE = -99999;

const initialState: {
    value: number,
} = {
    value: INITIAL_DATE,
}

export const curDateSlice = createSlice({
    name: 'curDate',
    initialState,
    reducers: {
        set: (state, action: PayloadAction<number>) => {
            state.value = action.payload;
        },
    }
})