import { GrayMatterFile } from "gray-matter";
import {
	assign,
	Dictionary,
	first,
	forEach,
	last,
	map,
	mapValues,
	pick,
	some,
	uniq,
} from "lodash";
import { CONTENT_WARNINGS } from "./contentWarnings.type";
import { HavenEvent } from "./havenEvent.type";
import { STORY_IDS } from "../.compiledContent/stories/STORY_IDS";
import { UrlOrString } from "./value.type";
import { REALM_IDS } from "../.compiledContent/realms/REALM_IDS";
import { ReaderMode } from "./readerMode.type";
import { CONTRIBUTOR_IDS } from "../.compiledContent/contributors/CONTRIBUTOR_IDS";
import { ARTWORK_IDS } from "../.compiledContent/artworks/ARTWORK_IDS";
import SemVer from "semver";
import { ROLES } from "./roles.type";
import { AGE_GROUPS } from "./ageGroup.type";

export interface StoryOrMetadataInterface {
	title: string;
	description: string;
	sortOrder?: number;
	color?: string;
	artworks?: ARTWORK_IDS[];
}

export interface StoryMetadataInterface extends StoryOrMetadataInterface {
	attribution: string;
	artworks?: ARTWORK_IDS[];
	event: HavenEvent;
	realms: REALM_IDS[];
	ageGroup: AGE_GROUPS;
	contentWarnings: CONTENT_WARNINGS[];
	parent: StoryCollection | undefined;
	version: string;
	music?: UrlOrString[];
	contributors: {
		readers: CONTRIBUTOR_IDS[],
		editors: CONTRIBUTOR_IDS[],
	}
	readerMode?: ReaderMode,
}

export interface CollectionMetadataInterface extends StoryOrMetadataInterface {

}

class StoryMetadata implements StoryMetadataInterface {
	// TODO: See if we can remove duplicate property declaration via declaration merging (https://www.typescriptlang.org/docs/handbook/declaration-merging.html), or by using Override class.
	id: STORY_IDS;
	filePath: string;

	title: string;
	description: string;
	attribution: string;
	artworks?: ARTWORK_IDS[];	
	event: HavenEvent;
	realms: REALM_IDS[];
	ageGroup: AGE_GROUPS;
	contentWarnings: CONTENT_WARNINGS[];
	parent: StoryCollection | undefined;
	version: string;
	sortOrder?: number;
	contributors: {
		readers: CONTRIBUTOR_IDS[];
		editors: CONTRIBUTOR_IDS[];
	}
	color: string;
	readerMode?: ReaderMode;

	constructor(def: Partial<StoryMetadata>) {
		assign(this, pick(def, Reflect.ownKeys(this)));
	}

	public getSupertitle(): string {
		if (this.parent) return `${this.parent.getSupertitle()}: ${this.title}`;
		return this.title;
	}
}

export interface StoryDef {
	title: string;
	description: string;
	attribution: string;
	event: HavenEvent;
	realms: REALM_IDS[];
	ageGroup: AGE_GROUPS;
	contentWarnings: CONTENT_WARNINGS[];
}

export interface StoryCollectionDef {
	title: string;
	description: string;
	children: Dictionary<StoryDef | StoryCollectionDef>;
	artworks?: ARTWORK_IDS[];
}

export class Story extends StoryMetadata implements StoryDef {
	public parent: StoryCollection;
	public event: HavenEvent;

	/** The Markdown of the story after GrayMatter parsing. */
	public md?: GrayMatterFile<string>;
	
	constructor(def: StoryDef, id: STORY_IDS) {
		super(def);
		this.id = id;
		this.event = def.event;
	}

	/** 
	 * Returns whether this is published to users of the given role type.
	 */
	public published(roles: ROLES[]): boolean {
		const requiredVersionMap: {[key in ROLES]?: string} = {
			[ROLES.READER]: "1.0.0",
			[ROLES.ALPHA_READER]: "0.1.0",
			[ROLES.BETA_READER]: "0.2.0",
			[ROLES.ADMIN]: "0.0.0",
		}
		const defaultRequiredVersion = '1.0.0';
		const requiredVersions: string[] = map(roles, role => requiredVersionMap[role] || defaultRequiredVersion);
		const minRequiredVersion = first(requiredVersions.sort(SemVer.compare)) || defaultRequiredVersion;
		return SemVer.satisfies(this.version, `>=${minRequiredVersion}`);
	}
}

export class StoryCollection
	extends StoryMetadata
	implements StoryCollectionDef
{
	public children: Dictionary<Story | StoryCollection>;
	constructor(def: StoryCollectionDef, id: STORY_IDS, parent?: StoryCollection) {
		super(def);
		this.attribution = "Various Sources";
		this.parent = parent;
		this.id = id;
		this.children = mapValues(def.children, (childDef, childId) =>
			createStoryOrCollection(childDef, `${this.id}_${childId}` as STORY_IDS)  // TODO: Figure out why this cast is necessary
		);
		this.loadMetadataForChildren();
	}

	private loadMetadataForChildren() {

		forEach(this.children, (c) => {
			c.parent = this;
		});

		this.realms = uniq(map(this.children, (c) => c.realms).flat());
		this.contentWarnings = uniq(
			map(this.children, (c) => c.contentWarnings).flat()
		);
		this.ageGroup = some(
			this.children,
			(c) => c.ageGroup === AGE_GROUPS.ADULT
		)
			? AGE_GROUPS.ADULT
			: AGE_GROUPS.YOUNG_ADULT;
		this.event = new HavenEvent(
			map(this.children, (child) => child.event.begin).sort(
				(a, b) => a - b
			)[0],
			map(this.children, (child) => child.event.end).sort(
				(a, b) => b - a
			)[0]
		);

	}

	/** Returns true if there's a child story that is published. */
	public published(roles: ROLES[]): boolean {
		return some(this.children, child => child.published(roles));
	}
}


export function createStoryOrCollection(
	def: StoryDef | StoryCollectionDef,
	id: STORY_IDS,
): Story | StoryCollection {
	if (isStoryDef(def)) return new Story(def, id);
	else return new StoryCollection(def, id);
}

function isStoryDef(def: StoryDef | StoryCollectionDef): def is StoryDef {
	return "event" in def;
}


