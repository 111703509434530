import { Artwork } from "../../types/artwork.type";
import s from "./ArtAttributionComponent.module.css";
import React from "react";
import { BsBrush } from "react-icons/bs";
import NiceModal from "@ebay/nice-modal-react";
import FootnoteModal from "../FootnoteContentComponent/FootnoteModal";
import Markdown from "markdown-to-jsx";

const ArtAttributionComponent: React.FC<{
	artwork: Artwork;
	label?: string;
}> = (p) => {
	const { artwork } = p;
	if (!artwork) return <></>;

	const tooltip = `${p.label || "Artist"} - Click for details.`;
	const { artist } = artwork;

	function showModal() {
		NiceModal.show(FootnoteModal, {
			children: (
				<div className={s.modal}>
					<h1>{artwork.title}</h1>
					<p>
						by {artist.href ? <a href={artist.href}>{artist.name}</a> : artist.name}
					</p>
					{artwork.notes && <p><Markdown children={artwork.notes}/></p>}
				</div>
			),
		});
	}

	return (
		<div
			className={s.wrapper}
			data-tooltip-id="tooltipId"
			data-tooltip-content={tooltip}
			onClick={showModal}
		>
			<span>
				<BsBrush />
			</span>
			{artist.name}
		</div>
	);
};

export default ArtAttributionComponent;
