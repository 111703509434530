import { cloneDeep, filter, intersection, map, omit, unset } from "lodash";
import { PropMask } from "./propMask.type";
import { ROLES } from "./roles.type";

/**
 * TODO: Replace this abstract class ith another pattern; the need to pass generics (rather than use `this`) to satisfy PropMask's recursive keys is getting ridiculous.
 */
export abstract class Restrictable<T extends object> {
	restrictions: PropMask<T>[];

	public getRedacted(roles: ROLES[]): Partial<T> {
		// Avoid mutating linked content.
		const clone = cloneDeep(this);

		// Get list of redactions
		const restrictionsForRole = filter(
			this.restrictions,
			(restriction) => !intersection(restriction.roles, roles).length
		);
		const redactions: string[] = map(restrictionsForRole, (r) =>
			r.prop.toString()
		).concat([
			'restrictions',
			'getRedacted',
		]);

		redactions.forEach(r => unset(clone,r));
		
		return clone as any as Partial<T>; // This `as any` seems unnecessary, but fixes an issue with ts-json-schema-generator.
	}
}
