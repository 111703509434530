import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get } from 'lodash';

const lsStr = window.localStorage.getItem('@@remember-rootState') ?? '{}';
const isAuthenticated = get(JSON.parse(lsStr), 'auth.isAuthenticated', false)


interface AuthInterface {
    /**
     * Indicates whether allowed to access the app (i.e. passed the password check.)
     */
    isAuthenticated: boolean,
}

const initialState: AuthInterface = {
    isAuthenticated,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        set: (state, action: PayloadAction<AuthInterface>) => {
            Object.assign(state, initialState, action.payload)
        },
    }
})
