import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { union } from 'lodash';
import { STORY_IDS } from '../.compiledContent/stories/STORY_IDS';


const initialState: {
    value: STORY_IDS[]
} = {
    value: [],
}

export const storiesReadSlice = createSlice({
    name: 'storiesRead',
    initialState,
    reducers: {
        add: (state, action: PayloadAction<STORY_IDS>) => {
            state.value = union(state.value, [action.payload]);
        },
    }
})