export enum AGE_GROUPS {
	YOUNG_ADULT = "YOUNG_ADULT",
	ADULT = "ADULT",
}

const valueMap: {[key in AGE_GROUPS]: string} = {
    ADULT: 'Adult (18+)',
    YOUNG_ADULT: 'Young Adult (12+)',
}

export function getAgeGroupTitle(ag: AGE_GROUPS) {
    return valueMap[ag];
}